<br><br><br><br>
  <div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>

  <div *ngIf="!loading">

    <div class="ui centered grid">
      <div class="computer only row">
        <div class="three wide column computer">
          <img width="150" height="150" src="{{imgurl}}">
        </div>
      

      <div class="ten wide column computer">
        <h2 class="ui secondary dividing header" id="mon3">{{spname}}</h2>
        <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="userprofileForm">

          <div class="field">
            <label>{{Logo | translate}}</label>
            <div class="ui left icon input">
              <i class="image icon"></i>
              <input type="file" name="logo" placeholder="Logo" (change)="fileChangeEvent($event)" [formControl]="logo">
            </div>
            <div class="ui small image">
              <img id="preview" src="" alt="Preview">
            </div>
            
          </div>

          <div class="ui red message" *ngIf="ph.errors && (ph.touched || ph.dirty)" id="mon3">
            {{'Please enter phone number' | translate}}
          </div>

          <div class="field">
            <label id="mon3">{{'Phone Number' | translate}}</label>
            <div class="ui left icon input">
              <i class="phone icon"></i>
            <input required type="text" required name="ph" placeholder="{{'Phone Number' | translate}}" [formControl]="ph" id="mon3">
            </div>
          </div>

          <div class="field">
            <label id="mon3">{{'Email' | translate}}</label>
            <div class="ui left icon input">
              <i class="mail icon"></i>
            <input type="text" name="email" placeholder="{{'Email' | translate}}" [formControl]="email" id="mon3">
            </div>
          </div>

          <div class="field">
            <label id="mon3">{{'Hotline' | translate}}</label>
            <div class="ui left icon input">
              <i class="phone icon"></i>
            <input type="text" name="hotline" placeholder="{{'Hotline' | translate}}" [formControl]="hotline" id="mon3">
            </div>
          </div>

          <div class="ui red message" *ngIf="address.errors && (address.touched || address.dirty)" id="mon3">
            {{'Please enter your address' | translate}}

          </div>

          <div class="field">
            <label id="mon3">{{'Address' | translate}}</label>
            <textarea rows = "3" [formControl]="address" name="address" placeholder="{{'Address' | translate}}" id="mon3" required></textarea>
          </div>

          <button id="mon3" class="ui secondary centered button" type="submit" [ngClass]="{disabled : userprofileForm.invalid}">
          {{'Update' | translate}}
        </button>
        </form>
      </div>
     </div>

      <div class="mobile only tablet only row">
        <div class="twelve wide centered column">
          <img width="150" height="150" src="{{imgurl}}" class="ui centered image"> <br>
        </div>

        <div class="twelve wide column">
          <h2 class="ui secondary dividing header" id="mon3">{{spname}}</h2>

          <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="userprofileForm">

              <div class="field">
                <label>{{'Logo' | translate }}</label>
                <div class="ui left icon input">
                <i class="image icon"></i>
                  <input type="file" name="logo" placeholder="Logo" (change)="fileChangeEvent($event)" [formControl]="logo">
                </div>
                <div class="ui small image">
                  <img id="preview" src="" alt="Preview">
                </div>
              </div>

              <div class="ui red message" *ngIf="ph.errors && (ph.touched || ph.dirty)" id="mon3">
                {{'Please enter phone number' | translate}}
              </div>

              <div class="field">
                <label id="mon3">{{'Phone Number' | translate}}</label>
                <div class="ui left icon input">
                <i class="phone icon"></i>
                  <input required type="text" required name="ph" placeholder="{{'Phone Number' | translate}}" [formControl]="ph" id="mon3">
                </div>
              </div>

              <div class="field">
                <label id="mon3">{{'Email' | translate}}</label>
                <div class="ui left icon input">
                <i class="mail icon"></i>
                  <input type="text" name="email" placeholder="{{'Email' | translate}}" [formControl]="email" id="mon3">
                </div>
              </div>

              <div class="field">
                <label id="mon3">{{'Hotline' | translate}}</label>
                <div class="ui left icon input">
                <i class="phone icon"></i>
                  <input type="text" name="hotline" placeholder="{{'Hotline' | translate}}" [formControl]="hotline" id="mon3">
                </div>
              </div>

              <div class="ui red message" *ngIf="address.errors && (address.touched || address.dirty)" id="mon3">
                {{'Please enter your address' | translate}}

              </div>

              <div class="field">
                <label id="mon3">{{'Address' | translate}}</label>
                <textarea rows = "3" [formControl]="address" name="address" placeholder="{{'Address' | translate}}" id="mon3" required></textarea>
              </div>

              <button id="mon3" class="ui secondary centered button" type="submit" [ngClass]="{disabled : userprofileForm.invalid}">
              {{'Update' | translate}}
            </button>
        </form>
        </div>
      </div>
    </div>

  </div>
  <br>