<br><br>
<!--<div class="spinner" *ngIf="loading">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">-->

  <div class="ui basic segment searchsegment">
    <div class="ui vertical feature segment">
      <div class="ui centered page grid">
        <div class="sixteen wide mobile eight wide computer twelve wide tablet column">
          <form class="ui centered form" [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()">

            <!--<div class="ui red message" *ngIf="name.errors && (name.touched || name.dirty)" id="mon3">
            Please fill in the Service Provider
          </div>-->
            <div class="field">
              <label id="mon3">{{'Service Provider' | translate}}</label>
              <input [formControl]="name" type="text" name="name" placeholder="Name" id="mon3">
            </div>
            <!--
          <div class="ui red message" *ngIf="township.errors && (township.touched || township.dirty)">
            Please choose the township
          </div>-->
            <div class="field">
              <label id="mon3">{{'Township' | translate}}</label>
              <select [formControl]="township" class="ui dropdown" name="township">
              <option id="mon3" value = "all">{{'All Townships' | translate}}</option>
              <option *ngFor="let township of townships" value="{{township.name}}" id="mon3">{{township.name | translate}}</option>
          </select>
            </div>

            <button id="mon3" class="ui centered blue button" type="submit">{{'Search' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="spinner" *ngIf="resultLoading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>

  <div *ngIf="!resultLoading">
    <div *ngIf="filteredItems">
      <div class="ui stackable centered grid">
        <div class="computer only four column row">
          <div class="twelve wide column">
            <div class="ui horizontal divider" *ngIf="nameString == ''">Service Providers in {{townshipString}} Township</div>
            <div class="ui horizontal divider" *ngIf="nameString">{{nameString}} in {{townshipString}} Township</div>
          </div>
        </div>

        <div class="tablet only mobile only four column row">
          <div class="twelve wide column">
            <div class="ui horizontal divider" *ngIf="nameString == ''">{{townshipString}}</div>
            <div class="ui horizontal divider" *ngIf="nameString">{{townshipString}}</div>
          </div>
        </div>
      </div>
      <br>
      <app-user-search-card [result]="filteredItems"></app-user-search-card>
    </div>
    <div *ngIf="filteredItems.length <= 0">
      <div class="ui centered container">
        <div class="ui page stackable column grid">
          <div class="row"></div>
          <div class="row"></div>
          <div class="row"></div>
          <div class="row">
            <div class="column">
              <div class="ui center aligned floating message">
                <p id="mon3">{{'No Service Providers' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--</div>-->