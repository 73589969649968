<div class="ui small fixed inverted navbar menu">
  <div class="ui text container">
 <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>
    <div class="right menu">
      <div class="ui right floated item">
        <div class="ui icon top center pointing dropdown button">
          <i class="world icon"></i>
          <div class="menu">
            <div class="item" id="mon3" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display | translate}}</div>
          </div>
        </div>
      </div>
      <div class="ui small right floated item" >
        <button class="ui tiny facebook button" id="mon3" (click)="login()">
          {{'Log In' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>