<br><br><br><br>

<div *ngIf="userAppointments">

  <div class="ui stackable three column centered grid container">
    <div class="three column row">

      <div class="five wide computer four wide tablet column">
        <div class="ui search">
          <div class="ui fluid input">
            <input id="mon3" type="text" placeholder="{{'Search by Name' | translate}}">
          </div>
        </div>
      </div>

      <div class="three wide computer four wide tablet column">
        <!--<select class="ui dropdown">
  <option value="">Gender</option>
  <option value="1">Male</option>
  <option value="0">Female</option>
</select>-->
        <div class="ui fluid floating labeled icon dropdown button" id="select">
          <i class="filter icon"></i>
          <span id="mon3" class="text">{{'Filter Tags' | translate}}</span>
          <div class="menu">
            <div id="mon3" class="header">
              {{'Filter by tag' | translate}}
            </div>
            <div class="divider"></div>
            <div class="item" data-value="all">
              <span class="text" id="mon3">{{'All' | translate }}</span>
            </div>
            <div class="item" data-value="pending">
              <span class="text" id="mon3">{{'Pending' | translate}}</span>
            </div>
            <div class="item" data-value="rescheduled">
              <span class="text" id="mon3">{{'Rescheduled' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="three wide computer four wide tablet column">
        <button id="mon3" class="ui fluid positive right labeled icon button" (click)="filterRequests()">
    {{'Search' | translate}}
    <i class="right search icon"></i>
  </button>
      </div>
    </div>
  </div>
</div>



<div class="cont">
  <div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>

  <div *ngIf="!loading">


    <div *ngIf="userAppointments">

      <div class="ui centered grid container">

        <div class="four column row">
          <div class="sixteen wide computer sixteen wide tablet column">
            <div class="ui stackable three cards">
              <div class="ui fluid raised card" *ngFor="let item of userAppointments">


                <div class="middle aligned content">

                  <!--<div class="ui items">
                    <div class="item">

                      <div class="ui tiny image">
                        <img class="image" src="{{item.imgurl}}">
                      </div>

                      <div class="middle aligned content">
                        <a data-tooltip="Cancel Appointment" data-position="bottom center" (click)="appointmentAction(item.apid,item.uid,item.spid,item.date,item.time,item.device,'cancel')"><i class="large right floated red trash icon"></i></a>
                        <a class="header">{{item.username}}</a>
                        <div class="meta">
                          <span *ngIf="item.state == 'rescheduled'">
                            <span style="margin:10px;" class="ui medium purple tag label" id="mon3">{{item.state}}</span>
                          </span>
                          <span *ngIf="item.state == 'pending'">
                            <span style="margin:10px;" class="ui medium blue tag label" id="mon3">{{item.state}}</span>
                          </span>
                          <span style="margin:10px;" class="ui small olive tag label" id="mon3">{{item.time}}</span>
                          <span style="margin:10px;" class="ui small teal tag label" id="mon3">{{item.date}}</span>
                        </div>
                        <div class="extra">
                          <button class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                      <i class="unordered list icon"></i>
                      View Appointment Details
                    </button>
                        </div>
                      </div>
                    </div>
                  </div>-->

                  <a><i (click)="cancelAppointment(item.apid,item.uid,item.spid,item.date,item.time,item.device,'cancel')" 
                  class="large right floated red trash icon"></i></a>
                  <span *ngIf="item.emergency == 'yes'">
                    <a class="ui red ribbon label" id="mon3">emergency</a>
                  </span> 
                  
                  <div class="header">
                    <h3 id="mon3">
                      <img class="ui tiny image" src="{{item.imgurl}}">{{item.username}}
                    </h3>
                    <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/Sp_Appointment_Detail', item.apid]">
                      <i class="unordered list icon"></i>
                      {{'View Appointment Details' | translate }}
                    </button>
                  </div>

                  <span *ngIf="item.state == 'rescheduled'">
                    <span style="margin:10px;" class="ui medium purple tag label" id="mon3">{{item.state | translate}}</span>
                  </span>
                  <span *ngIf="item.state == 'pending'">
                    <span style="margin:10px;" class="ui medium blue tag label" id="mon3">{{item.state  | translate}}</span>
                  </span>
                  <span style="margin:10px;" class="ui small olive tag label" id="mon3">{{item.time}}</span>
                  <span style="margin:10px;" class="ui small teal tag label" id="mon3">{{item.date}}</span>

                  <div class="description">
                    <table class="ui small definition unstackable table">
                      <tr>
                        <td class="active" id="mon3"><b>{{'Phone Number' | translate}}</b></td>
                        <td id="mon3">{{item.phone}}</td>
                      </tr>

                      <tr>
                        <td class="active" id="mon3"><b>{{'Address' | translate}}</b></td>
                        <td id="mon3">{{item.address}}</td>
                      </tr>

                      <tr>
                        <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                        <td id="mon3">{{item.device}}</td>
                      </tr>

                    </table>
                  </div>
                </div>

                <div class="extra content">
                  <button class="ui left floated tiny positive button" id="mon3" (click)="appointmentAction(item.apid,item.uid,item.spid,item.date,item.time,item.device,'approve')">{{'Approve' | translate}}</button>
                  <button class="ui left floated tiny blue button"  id="mon3" (click)="appointmentAction(item.apid,item.uid,item.spid,item.date,item.time,item.device,'reschedule')">{{'Reschedule' | translate}}</button>
                  <button class="ui left floated tiny teal button" id="mon3"><i class="phone icon"></i><a href="tel:{{item.phone}}">{{'Call to cancel' | translate}}</a></button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="userAppointments.length <= 0">
      <div class="ui centered container">
        <div class="ui page stackable column grid">
          <div class="row"></div>
          <div class="row"></div>
          <div class="row"></div>
          <div class="row">
            <div class="column">
              <div class="ui center aligned floating message">
                <p id="mon3">{{'No Queuing Appointments' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ui small reschedule-modal modal">
    <i class="close icon"></i>
    <div class="centered aligned content">
      <h3 id="mon3" class="ui centered header">{{'Reschedule Appointment' | translate}}</h3>
      <div class="ui horizontal divider">
        <div class="ui mini image"> <img src="./assets/icons/repairman.svg"></div>
      </div>
      <form class="ui small form" (ngSubmit)="onSubmit()" [formGroup]="spRescheduleForm">

        <div class="field">
          <label id="mon3">{{'Date' | translate}}</label>
          <select required class="ui dropdown" name="dateField" [formControl]="dateField">
            <option></option>
            <option *ngFor="let d of dateArray" [value]="d" >{{d}}</option>
        </select>
        </div>

        <div class="field">
          <label id="mon3">{{'Time' | translate}}</label>
          <select required class="ui dropdown" name="timeField" [formControl]="timeField">
            <option></option>
            <option>9am - 12pm</option>
            <option>12pm - 3pm</option>
            <option>3pm - 6pm</option>
        </select>
        </div>

        <button class="ui fluid blue button" type="submit" [ngClass]="{disabled : spRescheduleForm.invalid}">{{'Reschedule' | translate}}</button>

      </form>
    </div>

  </div>
</div>

<div class="ui basic sp-queuing-cancel-modal modal">

  <div class="ui center aligned grid">
    <div class="column">
      <i class="trash huge circle icon"></i>
      <h4 class="ui inverted center aligned header" id="mon3">{{'Are you sure you want to cancel the appointment?' | translate}}
      </h4>
    </div>

  </div>
  <div class="actions">
    <div class="ui negative approve button" (click)="deleteConfirm(apID, userID, spID, date, time, device, 'cancel')" id="mon3">{{'Okay' | translate}}</div>
    <div class="ui deny button" id="mon3" (click)="cancelModal()">{{'Cancel' | translate}}</div>
  </div>
</div>