<div class="ui small device-modal modal">
 
    <div class="ui center aligned grid">
      <div class="column">
        <i class="info huge circle icon"></i>
        <h4 class="ui center aligned header" id="mon3">Please choose a device first.</h4>
      </div>
      
    </div>
  <div class="actions">
      <div class="ui approve button" id="mon3">Okay</div>
  </div>
</div>

<!--ဘိုကင်တင်ရန်အိမ်သုံးပစ္စည်းအားပထမဦးစွာရွေးချယ်ပါ။ အိုကေ-->