
<!--<div *ngIf="currentURL == 1" class="setMargin">
    <div *ngIf="user == null || user == 'undefined' || user == ''">
        <app-user-login-navbar></app-user-login-navbar>
    </div>
    <div *ngIf="user">
        <app-user-navbar></app-user-navbar>
    </div>
</div>

<div *ngIf="currentURL == 2" class="setMargin">
    <div *ngIf="user">
    <app-sp-navbar></app-sp-navbar>
    </div>
 </div>-->

<div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">
 <div *ngIf="user">
    <div *ngIf="currentURL == 1">
        <app-user-navbar></app-user-navbar>
    </div>
</div>

<div *ngIf="sp">
    <div *ngIf="currentURL == 2">
        <app-sp-navbar></app-sp-navbar>
    </div>
</div>
 <div *ngIf="user == null || user == 'undefined' || user == ''">
    <div *ngIf="currentURL == 1">
        <app-user-login-navbar></app-user-login-navbar>
    </div>
</div>

</div>

