<br><br>
<div *ngIf="sp">
  <div class="imgsegment">
    <br>
    <div class="ui page center aligned grid">
      <div class="ui small image">
        <img width="200" height="200" src="{{sp.logo}}">
      </div>

    </div>
  </div>

  <div class="ui container">


    <div class="ui stacked fluid card">

      <div class="content">
        <h2 id="mon3" class="ui center aligned header">{{sp.company}}({{sp.branch}})</h2>
        <div class="ui grid">
          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3" class="ui dividing header">{{'Email' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="mail icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3">{{sp.email}}</span>
            </div>
          </div>

          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3" class="ui dividing header">{{'Branch' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="map icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3" >{{sp.branch}} branch</span>
            </div>
          </div>


          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3"  class="ui dividing header">{{'Township' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="map pin icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3" >{{sp.township}}</span>
            </div>
          </div>


          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3"  class="ui dividing header">{{'Phone Number' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="call icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3" >{{sp.ph}}</span>
            </div>
          </div>

          <div class="four column row" *ngIf="sp.hotline">
            <div class="sixteen wide column">
              <h4 id="mon3"  class="ui dividing header">{{'Hotline' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="call icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3" >{{sp.hotline}}</span>
            </div>
          </div>

          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3"  class="ui dividing header">{{'Services' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="heart icon"></i>
            </div>
            <div class="fourteen wide column">
              <span *ngFor="let service of sp.services">
              <span  id="mon3"  class="ui olive tag label">{{service}}</span>
              </span>
            </div>
          </div>

          <div class="four column row">
            <div class="sixteen wide column">
              <h4 id="mon3"  class="ui dividing header">{{'Address' | translate}}</h4>
            </div>
          </div>
          <div class="four column row">
            <div class="one wide column">
              <i class="address book icon"></i>
            </div>
            <div class="fourteen wide column">
              <span id="mon3" >{{sp.address}}</span>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!--<h2 class="ui center aligned header">Social Media</h2>
    <div class="ui horizontal divider">
      <i class="star icon"></i>
    </div>
    <h3 class="ui centered aligned header"><i class="blue circular facebook f icon"></i></h3>-->


  </div>
</div>


<br><br>