<br>
<div class="ui container">
    <h2 class="ui center aligned block header" id="mon3">{{'Appointment Details' | translate}}</h2>
</div>

<div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">
    <div *ngIf="user && appointment">
        <div class="ui stackable page grid">
           <div class="column">
            <div class="ui raised segment">
                <div class="content">
                    <img class="right floated ui tiny image" src="{{user.userimg}}">
                    <div class="header">
                        <span *ngIf="appointment.emergency == 'yes'">
                                <a class="ui small red ribbon label">emergency</a>
                            </span>
                        <h3 id="mon3">{{user.username}}</h3>
                    </div>
                    <span *ngIf="appointment.state == 'pending'">
                            <span style="margin:10px;" class="ui blue tag label" id="mon3">{{appointment.state | translate}}</span>
                    </span>
                    <span *ngIf="appointment.state == 'completed'">
                            <span style="margin:10px;" class="ui green tag label" id="mon3">{{appointment.state | translate}}</span>
                    </span>
                    <span *ngIf="appointment.state == 'rescheduled'">
                            <span style="margin:10px;" class="ui purple tag label" id="mon3">{{appointment.state | translate}}</span>
                    </span>
                    <span *ngIf="appointment.state == 'cancel'">
                                <span style="margin:10px;" class="ui orange tag label" id="mon3">{{'cancelled' | translate}}</span>
                    </span>
                </div>
                <br>
                <!--<div class="content">-->
                <div class = "description">
                <div class="ui large green label">{{'Appointment Details' | translate}}</div>
                <table class="ui small definition unstackable table">
                    <tr>
                        <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                        <td id="mon3">{{appointment.device}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Date' | translate}}</b></td>
                        <td id="mon3">{{appointment.date}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Time' | translate}}</b></td>
                        <td id="mon3">{{appointment.time}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Brand' | translate}}</b></td>
                        <td id="mon3">{{appointment.brand}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Description' | translate}}</b></td>
                        <td id="mon3">{{appointment.description}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Address' | translate}}</b></td>
                        <td id="mon3">{{appointment.userAddress}}</td>
                    </tr>
                </table>
                </div>

                <br>
                <div class="description">
                <div class="ui large green label">{{'Customer Details' | translate}}</div>
                <table class="ui small definition unstackable table">
                    <tr>
                        <td class="active" id="mon3"><b>{{'Name' | translate}}</b></td>
                        <td id="mon3">{{user.username}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Phone Number' | translate}}</b></td>
                        <td id="mon3">{{user.ph}}</td>
                    </tr>

                    <!--<tr>
                        <td class="active" id="mon3"><b>Address</b></td>
                        <td id="mon3">{{user.address}}</td>
                    </tr>-->

                    <tr>
                        <td class="active" id="mon3"><b>{{'Email' | translate}}</b></td>
                        <td id="mon3">{{user.email}}</td>
                    </tr>

                </table>
                </div>

            </div>
        </div>
        </div>
    </div>
</div>
<br>