<br>
<br><br><br><br>

<!-- User Page Accordian -->

<div class="ui stackable center aligned page grid">

  <div class="sixteen wide computer sixteen wide mobile column">
    <div class="ui styled inverted fluid accordion">
      <div class="title ui header" id="mon3">
        <i class="dropdown icon"></i> {{'How do we start?' | translate}}
      </div>
      <div class="content">
        <!--<p class="transition hidden">A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.</p>-->
        <div class="feature alternate ui stripe vertical segment">
          <div class="ui three column center aligned divided relaxed stackable grid container">
            <div class="row">

              <div class="column">
                <h3 class="ui header">{{'Step 1' | translate}}</h3>
                <div class="ui icon header">
                  <img src="./assets/icons/select.png">
                </div>
                <h4 id="mon3">{{'Please choose an appliance first' | translate}}.</h4>
              </div>
              <!--မိမိတို့ပြင်မည့်အိမ်သုံးပစ္စည်းကိုရွေးပါ။-->
              <div class="column">
                <h3 class="ui  header">{{'Step 2' | translate}}</h3>
                <div class="ui icon header">
                  <img src="./assets/icons/search.png">
                </div>
                <h4 id="mon3">{{'Search for the Service Provider' | translate}}</h4>
              </div>
              <!--Service ပေးသည့်ဆိုင်ကိုရှာပါ။-->
              <div class="column">
                <h3 class="ui  header">{{'Step 3' | translate}}</h3>
                <div class="ui icon header">
                  <img src="./assets/icons/weekly-calendar.png">
                </div>
                <h4 id="mon3">{{'Book the appointment' | translate}}</h4>
              </div>
              <!--ဆိုင်ကိုဘိုကင်တင်ပါ။-->

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- User Page Accordian -->
  <div *ngIf="user">
    <div *ngIf="pendingAppointments && rescheduledAppointments">
      <div class="sixteen wide computer sixteen wide mobile column">
        <div class="ui vertical stripe quote segment">
          <div class="ui equal width stackable celled grid">
            <div class="center aligned row">
              <div class="column">
                <h3 id="mon3">{{'Rescheduled Appointments' | translate}}</h3>
                <button class="ui purple circular button" [routerLink]="['/Requested_Service']">
            {{rescheduledAppointments.length}}
                </button>
              </div>
              <div class="column">
                <h3 id="mon3">{{'Pending Appointments' | translate}}</h3>
                <br>
                <button class="ui blue circular button" [routerLink]="['/Requested_Service']">
            {{pendingAppointments.length}}
                </button>
              </div>
              <div class="column">
                <h3 id="mon3">{{'Completed Appointments' | translate}}</h3>
                <button class="ui green completedButton circular button" [routerLink]="['/Requested_Service']">
            {{completedAppointments.length}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Choose Device -->
  <div class="sixteen wide computer sixteen wide mobile column">
    <div class="ui fluid message">
    <div class="ui center aligned header" id="mon3">{{'Choose the appliance' | translate}}</div>
    </div>
  </div>
  <!--အိမ်သုံးပစ္စည်းကိုရွေးချယ်ပါ။-->

  <div class="sixteen wide computer only sixteen wide tablet only column">
    <div class="ui basic segment device segment">
      <div class="ui vertical feature segment device-segment">
        <div class="ui centered page grid">
          <div class="row">
            <div class="sixteen wide column">
              <div class="ui three column center aligned stackable divided grid">
                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Air-conditioner' | translate}}</h3>
                  <!--အဲယားကွန်း-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerAirConditioner">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('air-conditioner')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui centered tiny image air-conditioner" src="./assets/icons/air-conditioner.png">
                  </div>
                  <!-- Dimmer -->
                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Generator' | translate}}</h3>
                  <!--မီးစက်-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerGenerator">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('generator')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui tiny centered image generator" src="./assets/icons/generator.png">
                  </div>
                  <!-- Dimmer -->
                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Water motor' | translate}}</h3>
                  <!--ရေမော်တာ-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerWaterMotor">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('water-motor')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui tiny centered image water-motor" src="./assets/icons/engine.png">
                  </div>
                  <!-- Dimmer -->
                </div>

              </div>
            </div>
          </div>



          <div class="row">
            <div class="sixteen wide column">
              <div class="ui three column center aligned stackable divided grid">

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Refrigerator' | translate}}</h3>
                  <!--ရေခဲသေတ္တာ-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerRefrigerator">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('refrigerator')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui tiny centered image refrigerator" src="./assets/icons/refrigerator.png">
                  </div>
                  <!-- Dimmer -->
                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Washing machine' | translate}}</h3>
                  <!--အဝတ်လျှော်စက်-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerWashingMachine">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('washing-machine')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui tiny centered image washing-machine" src="./assets/icons/washing-machine.png">
                  </div>
                  <!-- Dimmer -->
                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Voltage regulator' | translate}}</h3>
                  <!--မီးအားမြှင့်စက်-->
                  <!-- Dimmer -->
                  <div class="ui tiny centered image dimmable dimmerRegulator">
                    <div class="ui dimmer">
                      <!-- active -->
                      <div class="content">
                        <div class="center">
                          <h2 class="ui inverted icon header">
                            <div class="ui mini green button" id="mon3" (click)="device_click('regulator')">{{'Choose' | translate}}</div>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <img class="ui tiny centered image regulator" src="./assets/icons/regulator.png">
                  </div>
                  <!-- Dimmer -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Choose Device dropdown for mobile view -->
  <div class="sixteen wide mobile only column">

    <div class="ui basic segment device segment">
      <div class="ui vertical feature segment">
        <div class="ui centered page grid">
          <div class="row">
            <div class="ui large fluid search selection dropdown" id="dropdownMenu">
              <input type="hidden" name="device">
              <i class="dropdown icon"></i>
              <input class="search" tabindex="0">
              <div class="default text" id="mon3">{{'Select Appliance' | translate}}</div>
              <div class="menu transition hidden" tabindex="-1">
                <div class="item selected" data-value="air-conditioner" id="mon3">
                  <img class="ui tiny image" src="./assets/icons/air-conditioner.png"> {{'Air-conditioner' | translate}}
                </div>
                <div class="item" data-value="generator">
                  <img class="ui image" src="./assets/icons/generator.png" id="mon3"> {{'Generator' | translate}}
                </div>
                <div class="item" data-value="refrigerator">
                  <img class="ui image" src="./assets/icons/refrigerator.png" id="mon3"> {{'Refrigerator' | translate}}
                </div>
                <div class="item" data-value="washing-machine">
                  <img class="ui image" src="./assets/icons/washing-machine.png" id="mon3"> {{'Washing machine' | translate}}
                </div>
                <div class="item" data-value="water-motor">
                  <img class="ui image" src="./assets/icons/engine.png" id="mon3"> {{'Water motor' | translate}}
                </div>
                <div class="item" data-value="regulator">
                  <img class="ui image" src="./assets/icons/regulator.png" id="mon3"> {{'Voltage regulator' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <button id="mon3" class="ui right labeled icon teal button" (click)="searchMobile()">{{'Search' | translate}}<i class="right search icon"></i></button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Upcoming Appointments -->
  <div *ngIf="user">
    <br>
    <div class="sixteen wide computer sixteen wide mobile column" id="completed">
      <h3 class="ui center aligned header" id="mon3" >{{'Upcoming Appointments' | translate}}
        <div class="ui horizontal divider">
          <i class="checked calendar large icon"></i>
        </div>
      </h3>
    </div>

    <div class="spinner" *ngIf="loading">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>

    <div *ngIf="!loading">

      <div *ngIf="completedAppointments" >
        <!--<div class="center aligned row">-->
        <div class="sixteen wide column">
          <div class="ui stackable three cards">
            <div class="card" *ngFor="let item of completedAppointments">

              <div class="content">

                <div class="header">
                  <span *ngIf="item.emergency == 'yes'">
                      <a class="ui small red ribbon label">emergency</a>
                  </span>
                  <h3 id="mon3" [routerLink]="['/ServiceProvider_Profile', item.spid]"><img class="ui tiny image" src="{{item.spimg}}"> <a>{{item.company}} <small id="mon3">({{item.branch}})</small></a></h3>
                  <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                                <i class="unordered list icon"></i>
                                {{'View Appointment Details' | translate}}
                            </button>
                </div>
                <div class="meta">
                  <span *ngIf="item.state == 'pending'">
                    <span style="margin:10px;" class="ui blue tag label" id="mon3">{{item.state | translate}}</span>
                  </span>
                  <span *ngIf="item.state == 'completed'">
                    <span style="margin:10px;" class="ui green tag label" id="mon3">{{item.state | translate}}</span>
                  </span>
                  <span *ngIf="item.state == 'rescheduled'">
                    <span style="margin:10px;" class="ui purple tag label" id="mon3">{{item.state | translate}}</span>
                  </span>
                </div>

                <div class="description">
                  <table class="ui small definition unstackable table">
                    <tr>
                      <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                      <td id="mon3">{{item.device | translate}}</td>
                    </tr>
                    <tr>
                      <td class="active" id="mon3"><b>{{'Date' | translate}}</b></td>
                      <td id="mon3">{{item.date}}</td>
                    </tr>
                    <tr>
                      <td class="active" id="mon3"><b>{{'Time' | translate}}</b></td>
                      <td id="mon3">{{item.time}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="extra content">
                <div *ngIf="item.state == 'pending' || item.state == 'completed'">
                  <div class="ui red button" *ngIf="item.canCancel == true" (click)="cancelAppointment(item.apid,item.time,item.date,item.state,item.spid,
                                                            item.device,item.uid)" id="mon3">{{'Cancel Appointment' | translate}}</div>
                  <div id="mon3" class="ui button" *ngIf="item.canCancel == false">{{'Please call Service Provider to cancel the appointment.' | translate}}</div>
                </div>
                <!--<div *ngIf="item.state == 'rescheduled'">
                  <div id="mon3" class="ui positive button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                         item.state,item.uid,'accept')" id="mon3">{{'Accept' | translate}}</div>
                  <div id="mon3" class="ui button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                item.state,item.uid,'cancel')" id="mon3">{{'Cancel' | translate}}</div>

                </div>-->
              </div>
            </div>
          </div>
        </div>
        <!--</div>-->
        <!--</div>-->
      </div>
      <div *ngIf="completedAppointments.length <= 0">
        <div class="ui centered container">
          <div class="ui page stackable column grid">
            <div class="row"></div>
            <div class="row"></div>
            <div class="row"></div>
            <div class="row">
              <div class="column">
                <div class="ui center aligned floating message">
                  <p id="mon3">{{'No Upcoming Appointments' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-user-device-modal></app-user-device-modal>
<!--<div class="ui warning centered message">
        <h3 class="ui header">Do you require emergency service?</h3>
      </div>-->
<!--
      <div class="ui two column doubling grid">
        <div class="column">
          <app-user-device-modal></app-user-device-modal>
          <button class="full-circle circular ui green massive left floated button " (click)="search('yes')">
            <h2 class="ui header">Yes</h2>
          </button>
          <button class="full-circle circular ui green massive right floated button" (click)="search('no')">
            <h2 class="ui header">No</h2>
          </button>
        </div>

      </div>-->

<!--<div class="ui basic segment device segment">
  <div class="ui vertical feature segment">
    <div class="ui centered page grid">
      <div class="row">
        <div class="ui warning centered message">
          <h3 class="ui header" id="mon3">Are you in emergency?</h3>
        </div>
      </div>
      <div class="row">
        <div class="ui large buttons">
          <button class="ui warning large button" (click)="search('yes')" id="mon3">Yes</button>
          <div class="or"></div>
          <button class="ui large positive button" (click)="search('no')" id="mon3">No</button>
        </div>
      </div>
    </div>
  </div>
</div>-->



<br><br><br><br>

<div class="ui basic home-cancel-modal modal">

  <div class="ui center aligned grid">
    <div class="column">
      <i class="trash huge circle icon"></i>
      <h4 class="ui inverted center aligned header" id="mon3">{{'Are you sure you want to cancel the appointment?' | translate}}
      </h4>
    </div>

  </div>
  <div class="actions">
    <div class="ui negative approve button" (click)="deleteConfirm(apID,time,date,state,spid,ap_device,uid)" id="mon3">{{'Okay' | translate}}</div>
    <div class="ui deny button" id="mon3" (click)="cancelModal()">{{'Cancel' | translate}}</div>
  </div>
</div>