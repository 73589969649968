<br><br><br>
<div class="cont">
    <div class="spinner" *ngIf="loading">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>

    <div *ngIf="!loading">

        <div *ngIf="requestedAppointments">
            <div class="ui page grid">
                <div class="sixteen wide column">
                    <h3 id="mon3" class="ui dividing header">{{'Rescheduled Appointments' | translate}}</h3>
                </div>
                <div class="sixteen wide column">
                    <div class="ui stackable three cards">
                        <div class="card" *ngFor="let item of requestedAppointments" [ngClass]="{disabled : item.unavailable == 'true'}">

                            <div class="content">
                                
                                <div class="header">
                                    <span *ngIf="item.emergency == 'yes'">
                                        <a class="ui small red ribbon label">emergency</a>
                                    </span>
                                    <h3 id="mon3" [routerLink]="['/ServiceProvider_Profile', item.spid]"><img class="ui tiny image" src="{{item.spimg}}"> <a>{{item.company}}<small id="mon3">({{item.branch}})</small></a></h3>
                                    <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                                <i class="unordered list icon"></i>
                                {{'View Appointment Details' | translate}}
                            </button>
                                </div>
                                <div class="meta">
                                    <!--<span class="date">Create in Aug 2014</span>-->
                                    <span *ngIf="item.state == 'pending'">
                            <span style="margin:10px;" class="ui blue tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                    <span *ngIf="item.state == 'completed'">
                            <span style="margin:10px;" class="ui green tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                    <span *ngIf="item.state == 'rescheduled'">
                            <span style="margin:10px;" class="ui purple tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                </div>

                                <div class="description">
                                    <table class="ui small definition unstackable table">
                                        <tr>
                                            <td class="active" id="mon3"><b>{{'Device' | translate}}</b></td>
                                            <td id="mon3">{{item.device | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td class="active" id="mon3"><b>{{'Date'  | translate}}</b></td>
                                            <td id="mon3">{{item.date}}</td>
                                        </tr>
                                        <tr>
                                            <td class="active" id="mon3"><b>{{'Time'  | translate}}</b></td>
                                            <td id="mon3">{{item.time}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="extra content">
                                <div *ngIf="item.state == 'pending' || item.state == 'completed'">
                                    <div class="ui red button" *ngIf="item.canCancel == true" (click)="cancelAppointment(item.apid,item.time,item.date,item.state,item.spid,
                                                            item.device,item.uid)" id="mon3">{{'Cancel Appointment' | translate}}</div>
                                    <div class="ui button" *ngIf="item.canCancel == false">{{'Please call Service Provider to cancel the appointment.' | translate}}</div>
                                </div>
                                <div *ngIf="item.state == 'rescheduled'">
                                    <div class="ui positive button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                         item.state,item.uid,'accept')" id="mon3">{{'Accept' | translate}}</div>
                                    <div class="ui button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                item.state,item.uid,'cancel')" id="mon3">{{'Cancel' | translate}}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
            </div>
        </div>
        <div *ngIf="requestedAppointments.length <= 0">
            <div class="ui centered container">
                <div class="ui page stackable column grid">
                    <div class="row">
                        <div class="column">
                            <div class="ui center aligned floating message">
                                <p id="mon3">{{'No Rescheduled Appointments' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <!-- pending appointments -->

        <div *ngIf="appointments">
            <div class="ui page grid">
                <div class="sixteen wide column">
                    <h3 id="mon3" class="ui dividing header">{{'Appointments' | translate}}</h3>
                </div>
                <div class="sixteen wide column">
                    <div class="ui stackable three cards">
                        <div class="card" *ngFor="let item of appointments" [ngClass]="{disabled : item.unavailable == 'true'}">

                            <div class="content">
                                
                                <div class="header">
                                    <span *ngIf="item.emergency == 'yes'">
                                        <a class="ui small red ribbon label">emergency</a>
                                    </span>
                                    <h3 id="mon3" [routerLink]="['/ServiceProvider_Profile', item.spid]"><img class="ui tiny image" src="{{item.spimg}}"> <a>{{item.company}}<small id="mon3">({{item.branch}})</small></a></h3>
                                    <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                                <i class="unordered list icon"></i>
                                {{'View Appointment Details' | translate}}
                            </button>
                                </div>
                                <div class="meta">
                                    <!--<span class="date">Create in Aug 2014</span>-->
                                    <span *ngIf="item.state == 'pending'">
                            <span style="margin:10px;" class="ui blue tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                    <span *ngIf="item.state == 'completed'">
                            <span style="margin:10px;" class="ui green tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                    <span *ngIf="item.state == 'rescheduled'">
                            <span style="margin:10px;" class="ui purple tag label" id="mon3">{{item.state | translate}}</span>
                                    </span>
                                </div>

                                <div class="description">
                                    <table class="ui small definition unstackable table">
                                        <tr>
                                            <td class="active" id="mon3"><b>Device</b></td>
                                            <td id="mon3">{{item.device | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td class="active" id="mon3"><b>Date</b></td>
                                            <td id="mon3">{{item.date}}</td>
                                        </tr>
                                        <tr>
                                            <td class="active" id="mon3"><b>Time</b></td>
                                            <td id="mon3">{{item.time}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="extra content">
                                <div *ngIf="item.state == 'pending' || item.state == 'completed'">
                                    <div class="ui red button" *ngIf="item.canCancel == true" (click)="cancelAppointment(item.apid,item.time,item.date,item.state,item.spid,
                                                            item.device,item.uid)" id="mon3">{{'Cancel Appointment' | translate}}</div>
                                    <div class="ui button" *ngIf="item.canCancel == false">{{'Please call Service Provider to cancel the appointment.' | translate}}</div>
                                </div>
                                <div *ngIf="item.state == 'rescheduled'">
                                    <div class="ui positive button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                         item.state,item.uid,'accept')" id="mon3">{{'Accept' | translate}}</div>
                                    <div class="ui button" (click)="rescheduledAppointment(item.apid,item.date,item.time,item.device,item.spid,
                                                item.state,item.uid,'cancel')" id="mon3">{{'Cancel' | translate}}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
            </div>
        </div>
        <div *ngIf="appointments.length <= 0">
            <div class="ui centered container">
                <div class="ui page stackable column grid">
                    <div class="row">
                        <div class="column">
                            <div class="ui center aligned floating message">
                                <p id="mon3">{{'No Upcoming Appointments' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ui basic cancel-modal modal">

    <div class="ui center aligned grid">
        <div class="column">
            <i class="trash huge circle icon"></i>
            <h4 class="ui inverted center aligned header" id="mon3">{{'Are you sure you want to cancel the appointment?' | translate}}
            </h4>
        </div>

    </div>
    <div class="actions">
        <div class="ui negative approve button" (click)="deleteConfirm(apID,time,date,state,spid,device,uid)" id="mon3">{{'Okay' | translate}}</div>
        <div class="ui deny button" id="mon3" (click)="cancelModal()">{{'Cancel' | translate}}</div>
    </div>
</div>