<div *ngIf="result">
  <div class="ui stackable centered grid container">
    <div class="four column row">
      <div class="sixteen wide column">
        <div class="ui stackable three cards">
          <div class="card" *ngFor="let sp of result">

            <div class="content">
              <img class="left floated tiny ui image" width="150" height="150" src="{{sp.logo}}">
              <div class="header">
                <h3 id="mon3">{{sp.company.toString().split(' ')[0]}}<small>({{sp.branch}} Branch)</small></h3>
              </div>
            

            <div class="description">

              <table class="ui celled padded unstackable green table">

                <tbody>
                  <tr>
                    <td class="active" id="mon3">{{'Appliance' | translate}}</td>
                    <td id="mon3">{{sp.services.toString().split(',').join(', ')}}
                  </tr>

                  <tr>
                    <td class="active" id="mon3">{{'Address' | translate}}</td>
                    <td id="mon3">{{sp.address}}</td>
                </tbody>
              </table>
            </div>
            </div>

            <app-user-get-appointment [spinfo]="spinfo" id="sp.$key"></app-user-get-appointment>
          
            <div class="extra content">
              <div class="ui small two buttons">

                <!--<div class="ui green button" id="mon3" (click)="getAppointment(sp.$key)">
                  <h5 class="ui header">
                    <i class="large icons">
                    <i class="black calendar icon"></i>
                    <i class="inverted corner add icon"></i>
                    </i><br> Appointment
                  </h5>
                </div>-->
                <div class="ui green animated fade button" tabindex="0" (click)="getAppointment(sp.$key)">
                  <div class="visible content">
                    <i class="large icons">
                      <i class="black calendar icon"></i>
                      <i class="inverted corner add icon"></i>
                    </i>
                    </div>
                  <div class="hidden content">
                    <h5 id="mon3" class="ui header">{{'Get Appointment' | translate}}</h5>
                  </div>
                </div>

                <div class="ui blue animated fade button" tabindex="0" [routerLink]="['/ServiceProvider_Profile', sp.$key]">
                  <div class="visible content">
                    <i class="large icons">
                      <i class="black user icon"></i>
                    </i>
                    </div>
                  <div class="hidden content">
                    <h5 id="mon3" class="ui header">{{'View Profile' | translate}}</h5>
                  </div>
                </div>

                <!--<div class="ui blue button" id="mon3" [routerLink]="['/ServiceProvider_Profile', sp.$key]">
                  <h5 class="ui header">
                    <i class="large icons">
                      <i class="black user icon"></i>
                    </i>
                    <br> View Profile
                  </h5>
                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<br><br>



<div class="ui basic not-login-modal modal">
  <div class="ui icon header">
    <i class="info circle icon"></i>
    <p id="mon3">Please log in to book the appointment.</p>
  </div>

  <div class="actions">

    <div class="ui green ok inverted button" id="mon3" (click)="closeModal()">
      <i class="checkmark icon"></i> Okay
    </div>
  </div>
</div>