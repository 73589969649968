<br><br>
<div class="ui container">
  <h2 id="mon3" class="ui center aligned block header">{{'Notifications' | translate}}</h2>

  <div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>

  <div *ngIf="!loading">
    <div *ngIf="spNoti">
      <div class="ui middle aligned celled selection list">

        <div *ngFor="let noti of spNoti" class="item" [routerLink]="['/Sp_Noti_Appointment_Detail', noti.notiID]">
          <div *ngIf="noti.read == false" (click)="MarkAsRead(noti.notiID)" data-tooltip="Mark as read" data-position="top right"
          data-inverted="">
            <i class="right floated black circle icon"  id="{{noti.notiID}}" ></i>
          </div>
          <div *ngIf="noti.read == true" (click)="MarkAsRead(noti.notiID)" data-tooltip="Mark as unread" data-position="top right"
          data-inverted="">
            <i class="right floated circle thin icon"  id="{{noti.notiID}}" ></i>
          </div>
          <img class="ui avatar image" src="{{noti.userimg}}">
          <div class="content">

            <!--<div *ngIf="noti.state == 'rescheduled'">
              <div class="header">Your appointment is
                <a class="ui purple label">
            rescheduled 
          </a> by {{noti.spname}}.</div>
              <div class="description">
                <div>
                  <span>Date</span>
                  <span>{{noti.date}}</span>
                </div>
                <div>
                  <span>Time</span>
                  <span>{{noti.time}}</span>
                </div>
              </div>
            </div>-->

            <div *ngIf="noti.state == 'completed'">
              <div class="header">{{noti.username}} has <a class="ui green label"> accepted </a> your rescheduled time.</div>
              <div class="description">
                <div>
                  <span>Date</span>
                  <span>{{noti.date}}</span>
                </div>
                <div>
                  <span>Time</span>
                  <span>{{noti.time}}</span>
                </div>
              </div>
            </div>



            <div *ngIf="noti.state == 'cancel'">
              <div class="header">{{noti.username}} has <a class="ui orange label">cancelled</a> the appointment.</div>
            </div>
            <!--<div class="extra content">-->

            <!--</div>-->
          </div>
          <span class="right floated">
      <!--<span *ngIf="timestampToNoti(noti.timestamp)">{{return_string}}<span>-->
        {{noti.timestamp}}
    </span>
        </div>
      </div>
    </div>
    <div *ngIf="spNoti.length <= 0">
      <div class="ui centered container">
        <div class="ui page stackable column grid">
          <div class="row"></div>
          <div class="row"></div>
          <div class="row"></div>
          <div class="row">
            <div class="column">
              <div id="mon3" class="ui center aligned floating message">
                <p>{{'No Notifications' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>