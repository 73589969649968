  <div class="ui inverted vertical footer segment content">
    <div class="ui center aligned container">
      <div class="sixteen wide column">
        <div class="row">
          <img src="assets/icons/logo_zwenex_footer.png" class="ui centered small image">
        </div>
        <div class="ui center aligned inverted small link list">
          <a class="item"><i class="call icon"></i>+95-1-393082</a>
          <a class="item"><i class="address book icon"></i>Zwenexsys International Limited
          S9, 2nd Floor, Gyo Phyu Street, Mingalar Taung Nyunt Tsp, Yangon, Myanmar</a>
          <a class="item"><i class="mail icon"></i>contact@zwenex.com</a>
        </div>
        <!--<div class="ui horizontal center aligned inverted small link list">
          <a class="item"><i class="inverted blue large facebook f circular icon"></i></a>
        </div>-->
    </div>
    </div>
  </div>