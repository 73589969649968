<br><br><br><br>

<div class="spinner" *ngIf="loading">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">

  <div class="ui page grid">
    <div class="computer only centered row">
      <div class="three wide column computer">
        <img width="150" height="150" src="{{imgurl}}">
      </div>
      <div class="ten wide column computer">
        <h2 class="ui secondary dividing header" id="mon3">{{name}}</h2>
        <a class="ui large teal label" id="mon3">
          <i class="inverted black map pin icon"></i>
          {{townshipString}}
        </a>
        <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="userprofileForm" >

          <div class="ui red message" *ngIf="username.errors && (username.touched || username.dirty)" id="mon3">
            {{'Please enter your name' | translate}}
          </div>

          <div class="left aligned field">
            <label id="mon3">{{'Name' | translate}}</label>
            <div class="ui left icon input">
              <i class="user icon"></i>
              <input required [formControl]="username" type="text" name="username" placeholder="{{'Name' | translate}}" id="mon3">
            </div>
          </div>

          <div class="ui red message" *ngIf = "(ph.touched || ph.dirty) && ph.hasError('validPh')" id="mon3">
            {{'Please enter valid phone number' | translate}}
          </div>

          <div class="left aligned field">
            <label id="mon3">{{'Phone Number' | translate}}</label>
            <div class="ui left icon input">
              <i class="call icon"></i>
              <input required [formControl]="ph" type="text" name="ph" placeholder="{{'Phone Number' | translate}}" id="mon3">
            </div>
          </div>

          <div class="ui red message" *ngIf="email.errors && (email.touched || email.dirty)" id="mon3">
            {{'Please enter email address' | translate}}
          </div>

          <div class="field">
            <label id="mon3">{{'Email' | translate}}</label>
            <div class="ui left icon input">
              <i class="mail icon"></i>
              <input [formControl]="email" type="text" name="email" placeholder="{{'Email' | translate}}" id="mon3">
            </div>
          </div>

          <div class="field">
            <label>{{'Township' | translate}}</label>
            <div class="ui left icon input">
              <i class="map pin icon"></i>
              <select [formControl]="township" class="ui search dropdown" name="township">
                <option value="">{{'Select Township' | translate}}</option>
                <option *ngFor ="let t of townships" value="{{t.name}}" id="mon3">{{t.name}}</option>
              </select>
            </div>
          </div>

          <div class="ui red message" *ngIf="address.errors && (address.touched || address.dirty)" id="mon3">
            {{'Please enter your address' | translate}}
          </div>

          <div class="field">
            <label id="mon3">{{'Address' | translate}}</label>
            <textarea required rows="3" [formControl]="address" name="address" placeholder="{{'Address' | translate}}" id="mon3"></textarea>
          </div>

          <button id="mon3" class="ui disabled secondary centered button" type="submit" [ngClass]="{disabled : userprofileForm.invalid }">
          {{'Update' | translate}}
        </button>
        </form>
      </div>
    </div>

    <div class="mobile only tablet only centered row">
      <div class="twelve wide centered column">
        <img width="150" height="150" src="{{imgurl}}" class="ui centered image"> <br>
      </div>

      <div class="twelve wide column">
        <h2 class="ui secondary dividing header" id="mon3">{{name}}</h2>

        <form class="ui form" [formGroup]="userprofileForm" (ngSubmit)="onSubmit()">

          <div class="ui red message" *ngIf="username.errors && (username.touched || username.dirty)" id="mon3">
            {{'Please enter your name' | translate}}
          </div>

          <div class="left aligned field">
            <label id="mon3">Name</label>
            <div class="ui left icon input">
              <i class="user icon"></i>
              <input required [formControl]="username" type="text" name="username" placeholder="{{'Name' | translate}}" id="mon3">
            </div>
          </div>


          <div class="ui red message" *ngIf="ph.errors && (ph.touched || ph.dirty)" id="mon3">
           {{'Please enter valid phone number' | translate}}
          </div>

          <div class="field">
            <label id="mon3">{{'Phone Number' | translate}}</label>
            <div class="ui left icon input">
              <i class="call icon"></i>
              <input required [formControl]="ph" type="text" name="ph" placeholder="{{'Phone Number' | translate}}" id="mon3">
            </div>
          </div>

          <div class="ui red message" *ngIf="email.errors && (email.touched || email.dirty)" id="mon3">
            {{'Please enter email address' | translate}}
          </div>

          <div class="field">
            <label id="mon3">Email</label>
            <div class="ui left icon input">
              <i class="mail icon"></i>
              <input [formControl]="email" type="text" name="email" placeholder="{{'Email' | translate}}" id="mon3">
            </div>
          </div>

          <div class="ui red message" *ngIf="address.errors && (address.touched || address.dirty)" id="mon3">
            {{'Please enter your address' | translate}}
          </div>

          <div class="field">
            <label id="mon3">Address</label>
            <textarea required rows="3" [formControl]="address" name="address" placeholder="{{'Address' | translate}}" id="mon3"></textarea>
          </div>

          <button id="mon3" class="ui disabled secondary centered button" type="submit" [ngClass]="{disabled : userprofileForm.invalid }">
          {{'Update' | translate}}
        </button>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="pastAppointments">
    <div class="ui page grid">
      <!--<div class="center aligned row">-->
      <div class="sixteen wide column">
        <h3 id="mon3" class="ui center aligned header">{{'Past Appointments' | translate}}</h3>
        <div class="ui horizontal divider">
          <i class="large archive icon"></i>
        </div>
        <!--</div>-->
      </div>


      <!--<div class="center aligned row">-->
      <div class="sixteen wide column ">
        <!--<div class="sixteen wide column">-->
        <div class="ui stackable three cards">
          <div class="card" *ngFor="let item of pastAppointments">
            <div class="content">
              <!--<div class="ui red large right corner label"  (click)="deleteAppointment(item.apid)"><a data-tooltip="Cancel Appointment" data-position="bottom center"><i class="trash icon"></i></a></div>-->
              
              <div class="header">
                <span *ngIf="item.emergency == 'yes'">
                <a class="ui small red ribbon label">emergency</a>
              </span>
                <h3 id="mon3" [routerLink]="['/ServiceProvider_Profile', item.spid]"><img class="ui tiny image" src="{{item.spimg}}"> <a>{{item.company}}<small id="mon3">({{item.branch}})</small></a></h3>
                <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                  <i class="unordered list icon"></i>
                  {{'View Appointment Details' | translate}}
                </button>
              </div>
              <div class="meta" id="mon3">
                <span *ngIf="item.state == 'pending'">
                  <span style="margin:10px;" class="ui blue tag label" id="mon3">{{item.state | translate}}</span>
                </span>
                <span *ngIf="item.state == 'completed'">
                  <span style="margin:10px;" class="ui green tag label" id="mon3">{{item.state | translate}}</span>
                </span>
                <span *ngIf="item.state == 'rescheduled'">
                  <span style="margin:10px;" class="ui purple tag label" id="mon3">{{item.state | translate}}</span>
                </span>
                <span *ngIf="item.state == 'cancel'">
                  <span style="margin:10px;" class="ui orange tag label" id="mon3">{{'cancelled' | translate}}</span>
                </span>
              </div>

              <div class="description">
                <table class="ui small definition unstackable table">
                  <tr>
                    <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                    <td id="mon3">{{item.device | translate}}</td>
                  </tr>
                  <tr>
                    <td class="active" id="mon3"><b>{{'Date' | translate}}</b></td>
                    <td id="mon3">{{item.date}}</td>
                  </tr>
                  <tr>
                    <td class="active" id="mon3"><b>{{'Time' | translate}}</b></td>
                    <td id="mon3">{{item.time}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="extra content">
              <button id="mon3" class="ui small button" data-tooltip="Delete Appointment History" data-position="bottom center" (click)="deleteAppointment(item.apid)">{{'Delete History' | translate}}</button>
            </div>
            <!--</div>-->
          </div>
        </div>
      </div>
      </div>

      <div *ngIf="pastAppointments.length <= 0">
        <div class="ui centered container">
          <div class="ui page stackable column grid">
            <div class="row"></div>
            <div class="row">
              <div class="center aligned column">
                <div id="mon3" class="ui center aligned floating message">
                  {{'No Past Appointments' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    


  </div>

  <br>