<!--<div *ngIf = "spuser">-->
<div *ngIf="spname">
  <div class="ui grid">
    <div class="computer only row">
      <div class="ui small inverted fixed menu navbar page grid">
        <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>
        <div class="item">
          <div class="ui icon top center pointing dropdown button">
            <i class="world icon"></i>
            <div class="menu">
              <div class="item" id="mon3" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display | translate}}</div>
            </div>
          </div>
        </div>

        <!-- right menu -->
        <div class="right menu">
          <a [routerLink]="['Sp_Homepage']" class="item" routerLinkActive="active" id="mon3"><i class="home icon"></i>{{'Home' | translate}}</a>

          <a class="ui simple dropdown item" id="mon3">
          <i class="configure icon"></i>{{'Requests' | translate}}
          <i class="dropdown icon"></i>
          <div class="menu">
              <a class="item" routerLinkActive="active" [routerLink]="['ApprovedRequest']" id="mon3">{{'Approved Request' | translate}}</a>
          <a class="item" routerLinkActive="active" [routerLink]="['QueuingRequest']" id="mon3">{{'Queuing Request' | translate}}</a>
        </div>
        </a>


        <!--<a class="item" [routerLink]="['ApprovedRequest']">
          <div class="ui mini image">
            <svg width="25" height="25">
              <image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/icons/gears.svg" x="0" y="0" width="100%" height="100%"></image>
            </svg>
          </div>
          Approved Request</a>
        <a class="item" [routerLink]="['QueuingRequest']"><i class="configure icon"></i>Queuing Request</a>-->
        <!-- Notification -->
        <a class="spcustom item" id="mon3" routerLinkActive="active"><i class="alarm icon"></i>{{'Notification' | translate}}
        <a class="ui red circular label" id="mon3">{{notiLength.length}}</a></a>
        <app-sp-noti [spNoti]="spNoti"></app-sp-noti>

        <!-- Notification -->
        <a class="ui simple dropdown item" id="mon3">
          <div class="ui mini image">
            <img src="{{spimg}}">
          </div> {{spname}}
          <i class="dropdown icon"></i>
          <div class="menu">
            <div class="item" routerLinkActive="active" [routerLink]="['Sp_Profile']" id="mon3">{{'Profile' | translate}}</div>
            <div class="item" routerLinkActive="active" [routerLink]="['Sp_Support']" id="mon3">{{'Service Support' | translate}}</div>
            <div class="item" routerLinkActive="active" (click)="spLogout()" id="mon3">{{'Logout' | translate}}</div>
          </div>
        </a>
      </div>

    </div>
  </div>

  <div class="mobile only tablet only row">
    <div class="ui small fixed inverted navbar menu">
      <a class="menu item" (click)="side()">
          <i class="content icon"></i>
      </a>
 <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>

      <div class="right menu open">
        <div class="item">
          <div class="ui icon top center pointing dropdown button">
            <i class="world icon"></i>
            <div class="menu">
              <div class="item" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display}}</div>
            </div>
          </div>
        </div>
        <a class="item" [routerLink]="['SpNotifications']">
            <a class="ui red circular label" id="mon3">{{notiLength.length}}</a>
        <i class="large alarm icon"></i>
        </a>

      </div>
    </div>

    <div class="ui small left spsidebar sidebar inverted labeled icon vertical menu">
      <a class="header item" id="mon3">

         <i class="ui tiny image">
              <img src="{{spimg}}">
         </i><br><br>{{spname}}
       
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['Sp_Homepage']" id="mon3">
          <i class="home icon"></i>{{'Home' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['ApprovedRequest']" id="mon3">
        <!--<i class="white icon"><div class="ui  mini image"> 
              <svg width="25" height="25">
              <image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/icons/gears.svg" x="0" y="0" width="100%" height="100%"></image>
              </svg>
          </div></i>-->
        <i class="checked calendar icon"></i> {{'Approved Request' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['QueuingRequest']" id="mon3">
          <i class="configure icon"></i>
            {{'Queuing Request' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['Sp_Profile']" id="mon3">
          <i class="user icon"></i>
            {{'Profile' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['Sp_Support']" id="mon3">
        <i class="help circle icon"></i>
        {{'Service Support' | translate}}
      </a>
      <a class="item" routerLinkActive="active" (click)="spLogout()" id="mon3">
            <i class="sign out icon"></i>
            {{'Logout' | translate}}
        </a>
    </div>
    <!--</div>-->

  </div>
</div>
</div>
<!--</div>-->