<!--<div class="ui custom popup top center transition hidden">-->

<div *ngIf="userNoti">

  <div class="ui flowing custom popup bottom center transition hidden">
    <h2 id="mon3" class="ui top attached center aligned block header">{{'Notifications' | translate}}</h2>

    <div class="ui middle aligned selection scrolling list">

      <div *ngFor="let noti of userNoti" class="item" [routerLink]="['/User_Appointment_Detail', noti.notiID]">
        <!--[ngClass] = "{read : noti.read == 'true' , unread : noti.read == 'false'}"-->
         <!--<div *ngIf="noti.read == false">
      <i class="right floated black circle icon"  id="{{noti.$key}}"></i>
    </div>
    <div *ngIf="noti.read == true">
      <i class="right floated circle thin icon"  id="{{noti.$key}}"></i>
    </div>-->
        <img class="ui avatar image" src="{{noti.spimg}}">
        <div class="content">

          <div *ngIf="noti.state == 'rescheduled'">
            <div class="header">Your appointment is
              <a class="ui purple label">
                rescheduled
              </a> by {{noti.spname}}.</div>
            <div class="description">
              <div class="inline">
                <span>Date</span>
                <span>{{noti.date}}</span>
              </div>
              <div class="inline">
                <span>Time</span>
                <span>{{noti.time}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="noti.state == 'completed'">
            <div class="header">Your appointment for {{noti.device}} service is
              <a class="ui green label">
              approved 
            </a> by {{noti.spname}}.</div>
            <div class="description">
              <div class="inline">
                <span>Date</span>
                <span>{{noti.date}}</span>
              </div>
              <div class="inline">
                <span>Time</span>
                <span>{{noti.time}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="noti.state == 'cancel'">
            <div class="header">Your appointment for {{noti.device}} service is
              <a class="ui orange label">
              cancelled 
              </a> by {{noti.spname}}.</div>
          </div>
          <div class="extra content">
          <a>
            <i class="time icon"></i>
            {{noti.timestamp}}
          </a>
        </div>
        </div>

 
    </div>
    <!--<div *ngIf="userNoti.length >= 1">-->
      
    <!--</div>-->
  </div>
  <button id="mon3" class="ui bottom attached center aligned fluid button" [routerLink]="['Notifications']">{{'See All Notifications' | translate}}</button>
</div>
</div>
<div *ngIf="userNoti.length == 0">
  <div class="ui flowing custom popup bottom center transition hidden">
    <h2 id="mon3" class="ui top attached center aligned block header">{{'Notifications' | translate}}</h2>
    <div class="ui middle aligned selection list">
      <div class="item">
        <div id="mon3" class="header">{{'No New Notifications' | translate}}</div>
      </div>
    </div>
  </div>
</div>
