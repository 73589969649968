<br><br><br>
<div class="container">

  <div class="ui centered grid">
    <div class="sixteen wide computer fourteen wide mobile column">

      <div class="ui fluid raised card">

        <div class="content">
          <h3 id="mon3" class="center aligned">{{'Log In as Service Provider' | translate}}</h3>
          <div class="ui horizontal divider">
            <div class="ui mini image"> <img src="./assets/icons/repairman.svg"></div>
          </div>
          <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="spLogInForm">
            <!--<h4 class="ui dividing header">Contact Information</h4>-->

            <div class="ui red message" *ngIf="email.errors && (email.touched || email.dirty)" id="mon3">
              {{'Please enter email address' | translate}}
            </div>

            <div class="field">
              <div class="ui left icon input">
                <i class="user icon"></i>
                <input required type="text" name="email" placeholder="{{'Email' | translate}}" required [formControl]="email" id="mon3">
              </div>
            </div>

            <div class="ui red message" *ngIf="password.errors && (password.touched || password.dirty)" id="mon3">
              {{'Please enter password' | translate}}
            </div>
            <div class="field">
              <div class="ui left icon input">
                <i class="lock icon"></i>
                <input required type="password" name="password" placeholder="{{'Password' | translate}}" [formControl]="password" id="mon3">
              </div>
            </div>

            <button class="ui fluid positive button" type="submit" [ngClass]="{disabled : spLogInForm.invalid}" id="mon3">{{'Log In' | translate}}</button>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>