<br><br><br><br>
<div *ngIf="id">
<div class="ui centered grid">

  <div class="twelve wide computer fourteen wide mobile centered column">
    <div class="row">
      <h2 id="mon3" class="ui block header">{{'Service Support' | translate}}</h2>
    </div>
    <br><br>

    <div class="row">
      <div class="ui padded segment">
        <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="spSupportForm">

          <div class="field">
            <label id="mon3">{{'Subject' | translate}}</label>
            <div class="ui left icon input">
              <i class="comments icon"></i>
              <input id="mon3" required type="text"  name="subject" placeholder="{{'Subject' | translate}}" [formControl]="subject" id="mon3">
            </div>
          </div>

          <div class="field">
            <label id="mon3">{{'Feedback' | translate}}</label>
            <textarea id="mon3" rows="3" [formControl]="description" name="description" placeholder="{{'Feedback' | translate}}" id="mon3" required></textarea>
          </div>


          <button id="mon3" class="ui secondary centered button" type="submit" [ngClass]="{disabled : spSupportForm.invalid}">
              {{'Send Feedback' | translate}}
        </button>
        </form>
      </div>
    </div>

    <br><br>

    <div class="row">
      <h2 class="ui center aligned header" id="mon3">{{'Contact Us' | translate}}</h2>
      <div class="ui horizontal divider">
        <i class="talk icon"></i>
      </div>
    </div>

    <div *ngIf="contactData">
    <div class="fourteen wide column">
      <div class="ui three column center aligned stackable divided grid">
        <div class="column column-feature">
          <div class="ui tiny icon header">
            <i class="blue call icon"></i>
            <h4 id="mon3">{{contactData.phone}}</h4>
          </div>
        </div>
        <div class="column column-feature">
          <div class="ui tiny icon header">
            <i class="blue address book icon"></i>
            <h4 id="mon3">{{contactData.address}}</h4>
          </div>         
        </div>
        <div class="column column-feature">
          <div class="ui tiny icon header">
            <i class="blue mail icon"></i>
            <h4 id="mon3">{{contactData.email}}</h4>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
</div>