  <br><br>
  <div class="ui container">
  <div class="ui  top tiny attached steps">
    <div class="step">
      <i class="info circle icon"></i>
      <div class="content">
        <div class="title" id="mon3">Contact Information</div>
        <div class="description" id="mon3">Fill in Ph Number and Address </div>
      </div>
    </div>
  </div>

  <div>
    <div class="ui attached segment">
      <form class="ui form" [formGroup]="newUserForm" (ngSubmit)="onSubmit()">
        <h4 class="ui dividing header" id="mon3">Contact Information</h4>

        <div class="ui red message" *ngIf = "name.errors && (name.touched || name.dirty)" id="mon3">
              Please enter your name
        </div>
        <div class="field">
          <label id="mon3">Name</label>

        <div class="field">
            <input id="mon3" required type="text" name="name" placeholder="Name" [formControl]="name">
        </div>

        </div>

        <!--|| phone.errors.validPh-->
        <div class="ui red message" *ngIf = "(phone.touched || phone.dirty) && phone.hasError('validPh')" id="mon3">
              Please enter valid phone number
        </div>
        <div class="field">
          <label id="mon3">Phone Number</label>

        <div class="field">
            <input id="mon3" required type="text" name="phone" placeholder="Phone Number" [formControl]="phone">
        </div>

        </div>

        <div class="ui red message" *ngIf="township.errors && (township.touched || township.dirty)">
            Please choose the township
        </div>
        <div class="field">
          <label id="mon3">Township</label>
          <select [formControl]="township" required class="ui dropdown" name="township">
            <option *ngFor="let township of townships" value="{{township.name}}" id="mon3">{{township.name}}</option>
        </select>
        </div>

        <div class="ui red message" *ngIf = "address.errors && (address.touched || address.dirty)" id="mon3">
              Please enter your address
        </div>

        <div class="field">
          <label id="mon3">Address</label>

          <div class="field">
            <textarea id="mon3" required [formControl] = "address"></textarea>
          </div>

        </div>
        <button class="ui blue button" type="submit"   [ngClass] = "{disabled : newUserForm.invalid}">Submit</button>
      </form>
    </div>
  </div>
  </div>


