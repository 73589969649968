<br><br><br><br>
<div *ngIf="userAppointments">

<div class="ui stackable three column centered grid container">
  <div class="row">
  
  <div class="five wide computer four wide tablet column">
  <div class="ui fluid icon input" id="mon3">
    <input id="txtValue" type="text" placeholder="{{'Search by Name' | translate}}" (keyup)="filterRequests()">
    <i class="search icon"></i>
    <!--<button class="ui positive button" (click)="filterRequests()" >Search</button>-->
  </div>
  </div>
  
  <!--<div class="five wide computer four wide tablet column">
    <div class="ui search">
      <div class="ui fluid input">
        <input id="txtValue" type="text" placeholder="Search by Name">
      </div>
    </div>
  </div>

<div class="three wide computer four wide tablet column">
  <button class="ui fluid positive right labeled icon button" (click)="filterRequests()">
    Search
    <i class="right search icon"></i>
  </button>
</div>-->
</div>
</div>
</div>


<div class="cont">


<div class="spinner" *ngIf="loading">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">
  <div *ngIf = "userAppointments">
    <!--<div class="ui page stackable two column grid">
      <div class="sixteen wide column" >-->

        <div class="ui centered grid container">
        <div class="four column row">
          <div class="fourteen wide column">
        <div class="ui stackable three cards">
          <div class="card" *ngFor="let item of userAppointments">
          
          <div class="middle aligned content">

            <!--<div class="ui items">
              <div class="item">
                
                <div class="ui tiny image">
                    <img class="image" src = "{{item.imgurl}}">
                </div>
                
                <div class="middle aligned content">
                   
                  <a data-tooltip="Cancel Appointment" data-position="bottom center" 
                  (click)="appointmentAction(item.apid,item.uid,item.spid,item.date,item.time,item.device,'cancel')"><i class="large right floated red trash icon"></i></a>
                  <a class="header">{{item.username}}</a>
                  <div class="meta">
                    <span *ngIf="item.state == 'completed'">
                        <span style="margin:10px;" class="ui small green tag label" id="mon3">{{item.state}}</span>
                    </span>
                    <span style="margin:10px;" class="ui small olive tag label" id="mon3">{{item.time}}</span>
                    <span style="margin:10px;" class="ui small teal tag label" id="mon3">{{item.date}}</span>
                    
                  </div>
                  <div class="extra">
                    <button class="ui tiny inverted blue button" [routerLink]="['/User_Requested_Appointment_Detail', item.apid]">
                      <i class="unordered list icon"></i>
                      View Appointment Details
                    </button>
                  </div>
                </div>
              </div>
            </div>-->
        
            <a><i (click)="appointmentAction(item.apid,item.uid,item.spid,item.date,item.time,item.device,'cancel')" 
            class="large right floated red trash icon"></i></a>

            
            <div class="header">
              <span *ngIf="item.emergency == 'yes'">
                <a class="ui red ribbon label" id="mon3">emergency</a>
              </span>
              <h3 id="mon3"><img class="ui tiny image" src = "{{item.imgurl}}">  {{item.username}}</h3>
              <button id="mon3" class="ui tiny inverted blue button" [routerLink]="['/Sp_Appointment_Detail', item.apid]">
                  <i class="unordered list icon"></i>
                  {{'View Appointment Details' | translate}}
              </button>
            </div>
            <div class="meta">
              <span style="margin:10px;" class="ui small olive tag label" id="mon3">{{item.time}}</span>
              <span style="margin:10px;" class="ui small teal tag label" id="mon3">{{item.date}}</span>
              <span *ngIf="item.state == 'completed'">
                <span style="margin:10px;" class="ui small green tag label" id="mon3">{{item.state | translate}}</span>
              </span>
            </div>

            <div class="description">
              <table class="ui small definition unstackable table">
                <tr>
                  <td class="active" id="mon3"><b>{{'Phone Number' | translate}}</b></td>
                  <td id="mon3">{{item.phone}}</td>
                </tr>

                <tr>
                  <td class="active" id="mon3"><b>{{'Address' | translate}}</b></td>
                  <td id="mon3">{{item.address}}</td>
                </tr>

                <tr>
                  <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                  <td id="mon3">{{item.device | translate}}</td>
                </tr>

              </table>
            </div>
          </div>
          <div class="extra content">
            <div class="ui teal small button" id="mon3"><i class="phone icon"></i><a href="tel:{{item.phone}}">{{'Call to cancel' | translate}}</a></div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf = "userAppointments.length <= 0">
    <div class="ui centered container">
      <div class="ui page stackable column grid">
        <div class="row"></div>
            <div class="row"></div>
            <div class="row"></div>
            <div class="row">
                <div class="column">
                <div class="ui center aligned floating message">
                    <p id="mon3">{{'No Approved Appointments' | translate}}</p>
                </div>
                </div>
            </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="ui basic sp-approved-cancel-modal modal">

    <div class="ui center aligned grid">
        <div class="column">
            <i class="trash huge circle icon"></i>
            <h4 class="ui inverted center aligned header" id="mon3">{{'Are you sure you want to cancel the appointment?' | translate}}
            </h4>
        </div>

    </div>
    <div class="actions">
        <div class="ui negative approve button" (click)="deleteConfirm(apID, userID, spID, date, time, device, 'cancel')" id="mon3">{{'Okay' | translate}}</div>
        <div class="ui deny button" id="mon3" (click)="cancelModal()">{{'Cancel' | translate}}</div>
    </div>
</div>