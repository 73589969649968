<div class="ui grid">
  <div class="computer only row">
    <div class="ui small inverted fixed menu navbar page grid">
 <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>
      <div class="item">
        <div class="ui icon top center pointing dropdown button">
          <i class="world icon"></i>
          <div class="menu">
            <div class="item" id="mon3" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display | translate}}</div>
          </div>
        </div>
      </div>
      <!-- right menu -->
      <div class="right menu">
        <a [routerLink]="['User_Homepage']" class="item" id="mon3" routerLinkActive="active"><i class="home icon"></i>{{'Home' | translate}}</a>
        <a class="item" [routerLink]="['Requested_Service']" id="mon3" routerLinkActive="active"><i class="configure icon"></i>{{'Requested Service' | translate}}</a>

        <!-- Notification -->
        <a class="custom item" id="mon3" routerLinkActive="active">
          <i class="alarm icon"></i>{{'Notification' | translate}}
          <a class="ui red circular label" id="mon3">{{notiLength.length}}</a>
        </a>

        <!-- End Notification -->

        <a class="ui simple dropdown item" id="mon3">
          <div class="ui mini image">
            <img src="{{userimg}}">
          </div>{{username}}
          <i class="dropdown icon"></i>
          <div class="menu">
            <div class="item" routerLinkActive="active" [routerLink]="['Profile']" id="mon3" >{{'Profile' | translate}}</div>
            <div class="item" routerLinkActive="active" [routerLink]="['User_Support']" id="mon3">{{'Customer Support' | translate}}</div>
            <div class="item" routerLinkActive="active" (click)="logout()" id="mon3">{{'Logout' | translate}}</div>
          </div>
        </a>
      </div>

    </div>
  </div>
  <!-- Mobile Navbar -->
  <div class="mobile only tablet only row">
    <div class="ui fixed small inverted navbar menu">
      <a class="menu item" (click)="side()">
            <i class="content icon"></i>
        </a>
 <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>

      <div class="right menu">
      <div class="item">
        <div class="ui icon top center pointing dropdown button">
          <i class="world icon"></i>
          <div class="menu">
            <div class="item" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display}}</div>
          </div>
        </div>
      </div>
        <a class="item" [routerLink]="['Notifications']">
          <a class="ui red circular label" *ngIf="notiLength.length > 0" id="mon3">{{notiLength.length}}</a>
          <i class="large alarm icon"></i>
        </a>
      </div>
    </div>

    <div class="ui left small usersidebar sidebar inverted labeled icon vertical menu">
      <a class="item" routerLinkActive="active" id="mon3">
            <i class="ui mini image">
              <img src="{{userimg}}">
            </i><br><br>{{username}}
          </a>
      <a class="item" routerLinkActive="active" [routerLink]="['User_Homepage']" id="mon3">
         <i class="home icon"></i>{{'Home' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['Requested_Service']" id="mon3">
        <i class="configure icon"></i>
          {{'Requested Service' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['Profile']" id="mon3">
          <i class="user icon"></i>
            {{'Profile' | translate}}
      </a>
      <a class="item" routerLinkActive="active" [routerLink]="['User_Support']" id="mon3">
          <i class="help circle icon"></i>
            {{'Customer Support' | translate}}
      </a>
      <a class="item" routerLinkActive="active" (click)="logout()" id="mon3">
          <i class="sign out icon"></i>
          {{'Logout' | translate}}
      </a>
    </div>


  </div>
</div>
<app-user-noti [userNoti]="userNoti"></app-user-noti>
<!-- Notification Popup -->