<br>
<div class="ui container">
    <h2 class="ui center aligned block header" id="mon3">{{'Appointment Details' | translate}}</h2>
</div>

<div class="spinner" *ngIf="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
</div>

<div *ngIf="!loading">
    <div *ngIf="sp && appointment">
        <div class="ui stackable page grid">
        <div class="column">
         <!--<div class="column">-->
           <div class="ui raised segment">
                <div class="content">

                <!--<div class="ui items">
                    <div class="item">
                        
                        <div class="ui tiny image">
                            <img class="image" src = "{{sp.spimg}}">
                        </div>
                        
                        <div class="middle aligned content">
                            <a class="header" id="mon3">{{sp.company}}<small id="mon3">({{sp.branch}} Branch)</small></a>
                            <div class="meta">
                                <span *ngIf="appointment.state == 'pending'">
                                    <span style="margin:10px;" class="ui blue tag label" id="mon3">{{appointment.state}}</span>
                                </span>
                                <span *ngIf="appointment.state == 'completed'">
                                    <span style="margin:10px;" class="ui green tag label" id="mon3">{{appointment.state}}</span>
                                </span>
                                <span *ngIf="appointment.state == 'rescheduled'">
                                    <span style="margin:10px;" class="ui purple tag label" id="mon3">{{appointment.state}}</span>
                                </span>
                                <span *ngIf="appointment.state == 'cancel'">
                                    <span style="margin:10px;" class="ui orange tag label" id="mon3">cancelled</span>
                                </span> 
                            </div>
                        </div>
                    </div>
                    </div>-->

                    <!--<span *ngIf="appointment.emergency == 'yes'">
                                <a class="ui small red ribbon label">emergency</a>
                        </span>-->


                    <img class="right floated ui tiny image" src="{{sp.spimg}}">
                    <div class="header">
                        
                        <h3 id="mon3">{{sp.company}}<small id="mon3">({{sp.branch}} Branch)</small></h3>
                    </div>
                    <div class="meta">
                        <span *ngIf="appointment.state == 'pending'">
                                <span style="margin:10px;" class="ui blue tag label" id="mon3">{{appointment.state | translate}}</span>
                        </span>
                        <span *ngIf="appointment.state == 'completed'">
                                <span style="margin:10px;" class="ui green tag label" id="mon3">{{appointment.state | translate}}</span>
                        </span>
                        <span *ngIf="appointment.state == 'rescheduled'">
                                <span style="margin:10px;" class="ui purple tag label" id="mon3">{{appointment.state | translate}}</span>
                        </span>
                        <span *ngIf="appointment.state == 'cancel'">
                                <span style="margin:10px;" class="ui orange tag label" id="mon3">{{'cancelled' | translate}}</span>
                        </span>
                    </div>
                
               
               <div class="description">
                <!--<div class="ui large green label">Appointment Details</div>-->
                <div class="ui horizontal divider">{{"Appointment Details" | translate}}</div>
                <table class="ui small definition unstackable table">
                    <tr>
                        <td class="active" id="mon3"><b>{{'Appliance' | translate}}</b></td>
                        <td id="mon3">{{appointment.device | translate}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Date' | translate}}</b></td>
                        <td id="mon3">{{appointment.date}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Time' | translate}}</b></td>
                        <td id="mon3">{{appointment.time}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Brand' | translate}}</b></td>
                        <td id="mon3">{{appointment.brand}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Description' | translate}}</b></td>
                        <td id="mon3">{{appointment.description}}</td>
                    </tr>

                    <tr>
                    <td class="active" id="mon3"><b>{{'Address' | translate}}</b></td>
                        <td id="mon3">{{appointment.userAddress}}</td>
                    </tr>
                </table>
                </div>
                <br>
                <div class="description">
                <div class="ui horizontal divider">{{'Service Provider Details' | translate}}</div>
                <table class="ui small definition unstackable table">
                    <tr>
                        <td class="active" id="mon3"><b>{{'Name' | translate}}</b></td>
                        <td id="mon3">{{sp.company}}</td>
                    </tr>
                    <tr>
                        <td class="active" id="mon3"><b>{{'Branch' | translate}}</b></td>
                        <td id="mon3">{{sp.branch}}</td>
                    </tr>

                    <tr *ngIf="sp.hotline">
                        <td class="active" id="mon3"><b>{{'Hotline' | translate}}</b></td>
                        <td id="mon3">{{sp.hotline}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Phone' | translate}}</b></td>
                        <td id="mon3">{{sp.ph}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Address' | translate}}</b></td>
                        <td id="mon3">{{sp.address}}</td>
                    </tr>

                    <tr>
                        <td class="active" id="mon3"><b>{{'Services' | translate}}</b></td>
                        <td id="mon3">{{sp.services}}</td>
                    </tr>

                </table>
                </div>
            </div>
        </div>
         <!--</div>-->
        </div>
        </div>
    </div>
</div>
<br>