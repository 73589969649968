<div class="ui small modal appointment-modal">
  <i class="close icon"></i>
  <div class="header">
    <h1>Appointment Form</h1>
  </div>
  <div class="content">

    <div class="ui very padded blue stacked segment">

          <form class="ui form" [formGroup] = "spForm" (ngSubmit)="onSubmit()">

            <!--<div class="ui red message" *ngIf = "name.errors && (name.touched || name.dirty)" id="mon3"> 
              Please enter your name
            </div>
            <div class="field">
              <label id="mon3">Name</label>
              <input [formControl] = "name" required type="text" name="name" placeholder="Name"id="mon3">
            </div>

            <div class="ui red message" *ngIf = "ph.errors && (ph.touched || ph.dirty)" id="mon3">
              Please enter your phone number
            </div>

            <div class="field">
              <label id="mon3">Phone</label>
              <input [formControl] = "ph" type="text" name="ph" placeholder="Phone" id="mon3" required >
            </div>

            <div class="field">
              <label id="mon3">Email</label>
              <input [formControl] = "email" type="email" name="email" placeholder="Email" id="mon3">
            </div>

            <div class="ui red message" *ngIf = "address.errors && (address.touched || address.dirty)" id="mon3">
              Please enter your address
            </div>-->

            <div class="field" >
              <label id="mon3">Address</label>
              <input [formControl] = "address" type="text" name="address" placeholder="Address" id="mon3" required>
            </div>

            <div class="ui red message" *ngIf = "description.errors && (description.touched || description.dirty)" id="mon3">
              Please choose description
            </div>

            <div class="field">
              <label id="mon3">Description</label>
              <select required  class="ui dropdown" [formControl] = "description" name="description" id="mon3" >
                <option *ngFor="let description of descriptionArray" [value]="description" >{{description}}</option>
              </select>
            </div>

            <div class="ui red message" *ngIf = "other_description.errors && (other_description.touched || other_description.dirty)" id="mon3">
              Please specify descriptions for your damaged device.
            </div>

            <div *ngIf="description.value == 'Other'">
              <div class="field">
                <label id="mon3">If other, pls specify</label>
                <input [formControl] = "other_description" type="text" name="other_description" placeholder="Other Description" id="mon3">
              </div>
            </div>

            <div class="field">
              <label id="mon3">Brand (Optional)</label>
              <input [formControl] = "brand" type="text" name="brand" placeholder="Brand" id="mon3">
            </div>

            <div class="ui red message" *ngIf = "date.errors && (date.touched || date.dirty)" id="mon3">
              Please choose date
            </div>

            <div class="field" *ngIf="date2">
              <label id="mon3">Date</label>
              <select  required class="ui dropdown"  [formControl]="date" name="date">
                <option *ngFor="let d of date2" [value]="d" >{{d}}</option>
              </select>
            </div>

            <div *ngIf="date.value == date2[0]">
              <div class="ui red message" *ngIf = "todayTime.errors && (todayTime.touched || todayTime.dirty)" id="mon3">
                Please choose time slot
              </div>
              <div class="ui yellow message" *ngIf = "todayTime.length <= 0" id="mon3">
                Please choose other days for the appointment. 
              </div>

              <div class="field">
                <label id="mon3">Time Slot</label>
                <select required  class="ui dropdown" [formControl] = "time" name="time" [ngClass]="{disabled : todayTime.length <= 0 }" [ngClass]="{disabled : date.value == null || date.value == '' }">
                  <option *ngFor="let t of todayTime" [value]="t" >{{t}}</option>
                </select>
              </div>
            </div>

            <div *ngIf="date.value !== date2[0]">
            <div class="ui red message" *ngIf = "time.errors && (time.touched || time.dirty)" id="mon3">
              Please choose time slot
            </div>

             <div class="field">
              <label id="mon3">Time Slot</label>
              <select required  class="ui dropdown" [formControl] = "time" name="time" [ngClass]="{disabled : date.value == date2[0] }">
                <option *ngFor="let t of time2" [value]="t" >{{t}}</option>
              </select>
            </div>
            </div>
            <br>
            <button class="ui disabled blue button" type="submit" [ngClass] = "{disabled : spForm.invalid}" id="mon3">Get The Appointment</button>
          </form>

</div>
  </div>
</div>