<!-- menu and main cover -->
<div class="ui inverted vertical masthead center aligned segment">

  <div class="ui container">
    <div class="ui secondary pointing menu">
      <div href="#" class="header item">
        <img class="ui tiny image"  src="assets/icons/FixIt.png">
      </div>
      <div class="right menu">
      <div class="ui right floated item">
        <div class="ui icon top center pointing dropdown button">
          <i class="world icon"></i>
          <div class="menu">
            <div id="mon3" class="item" *ngFor="let lang of supportedLangs" value='lang.value' (click)="selectLang(lang.value)">{{lang.display | translate}}</div>
          </div>
        </div>
      </div>
      <div class="ui  right floated dropdown item" id="mon3">
        <span id="itemText">{{'Service Provider' | translate}}<i class="inverted dropdown icon"></i></span>
        <div class="menu">
          <div class="inverted item" [routerLink]="['/Sp_LogIn']">{{'Log In' | translate}}</div>
          <div class="inverted here item">{{'Register' | translate}}</div>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div class="ui computer only tablet only centered four column doubling stackable grid container">

    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row">
      <img class="ui image" src="assets/icons/wrench.png">
    </div>
    <div class="four column row"></div>
    <div class="four column row">
      <h2 id="coverText">REPAIR SERVICE</h2>
    </div>
    <div class="four column row">
      <h4 id="subText">TO YOUR DOOR STEPS</h4>
    </div>
    <div class="row">
      <button id="mon3" class="blue center aligned massive ui button" [routerLink]="['/User_Homepage']">{{'Get The Appointment' | translate}}</button>
    </div>

  </div>

  <div class="ui mobile only centered four column doubling stackable grid container">

    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row"></div>
    <div class="four column row">
      <img class="ui image" src="assets/icons/wrench.png">
    </div>
    <div class="four column row"></div>
    <div class="four column row">
      <h2 id="mobileCoverText">REPAIR SERVICE</h2>
    </div>
    <div class="four column row">
      <h4 id="mobileSubText">TO YOUR DOOR STEPS</h4>
    </div>
    <br><br>
    <div class="row">
      <button id="mon3" class="blue center aligned large ui button" [routerLink]="['/User_Homepage']">{{'Get The Appointment' | translate}}</button>
    </div>

  </div>
</div>
<br><br><br>

<!-- "What are we" segment -->
<div class="ui text container">
  <h2 class="ui center aligned icon header">
    <!--<i class="circular users icon"></i>-->
    <img class="ui image massive" src="/assets/icons/team2.png">
  <h2 id="mon3" class="ui center aligned header">{{'What are we?' | translate}}</h2>

    <div class="ui piled very padded segment">
      <h3 id="mon3">{{'An online appointment service to book appointment to service providers in Yangon for fixing damaged home appliances to your door steps.' | translate}}
      </h3>
    </div>
  </h2>
</div>
<br><br><br><br><br>

<div class="ui centered grid container">
  <div class="sixteen wide column">
    <h2 id = "mon3" class="ui center aligned dividing header">{{'What Can You Repair?' | translate}}</h2>
  </div>
  <div class="sixteen wide computer only sixteen wide tablet only column">
    <div class="ui basic segment device segment">
      <div class="ui vertical feature segment device-segment">
        <div class="ui centered page grid">
          <div class="row">
            <div class="sixteen wide column">
              <div class="ui three column center aligned stackable divided grid">
                <div class="column column-feature">
                  <h3 id="mon3" class="ui centered header">{{ 'Air-conditioner' | translate }}</h3>
                  <!--အဲယားကွန်း-->

                  <div class="ui tiny centered image">
                    <img class="ui centered tiny image air-conditioner" src="./assets/icons/air-conditioner.png">
                  </div>

                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Generator' | translate }}</h3>
                  <!--မီးစက်-->
                  <div class="ui tiny centered image">
                    <img class="ui tiny centered image generator" src="./assets/icons/generator.png">
                  </div>

                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Water motor' | translate }}</h3>
                  <!--ရေမော်တာ-->

                  <div class="ui tiny centered image">
                    <img class="ui tiny centered image water-motor" src="./assets/icons/engine.png">
                  </div>

                </div>

              </div>
            </div>
          </div>



          <div class="row">
            <div class="sixteen wide column">
              <div class="ui three column center aligned stackable divided grid">

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Refrigerator' | translate }}</h3>
                  <!--ရေခဲသေတ္တာ-->

                  <div class="ui tiny centered image">
                    <img class="ui tiny centered image refrigerator" src="./assets/icons/refrigerator.png">
                  </div>

                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Washing machine' | translate }}</h3>
                  <!--အဝတ်လျှော်စက်-->

                  <div class="ui tiny centered image">
                    <img class="ui tiny centered image washing-machine" src="./assets/icons/washing-machine.png">
                  </div>

                </div>

                <div class="column column-feature">
                  <h3 class="ui centered header" id="mon3">{{'Voltage regulator' | translate}}</h3>
                  <!--မီးအားမြှင့်စက်-->

                  <div class="ui tiny centered image">
                    <img class="ui tiny centered image regulator" src="./assets/icons/regulator.png">
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sixteen wide mobile only column">
    <div class="ui raised segments">
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
          <img  class="ui image" src="./assets/icons/air-conditioner.png"> {{ 'Air-conditioner' | translate }}
        </div>
      </div>
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
          <img  class="ui image" src="./assets/icons/refrigerator.png"> {{'Refrigerator' | translate }}
        </div>
      </div>
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
          <img  class="ui image" src="./assets/icons/washing-machine.png"> {{'Washing machine' | translate }}
        </div>
      </div>
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
        <img  class="ui image" src="./assets/icons/engine.png"> {{'Water motor' | translate }}
        </div>
      </div>
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
          <img  class="ui image" src="./assets/icons/generator.png"> {{'Generator' | translate }}
        </div>
      </div>
      <div class="ui center aligned segment">
        <div class="ui icon header" id="mon3">
          <img  class="ui image" src="./assets/icons/regulator.png"> {{'Voltage regulator' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<br><br><br>

<!-- User Information Segment -->
<div class="ui basic segment">
  <div class="ui vertical feature segment ">
    <div class="ui centered page grid usersegment">

      <div class="row">
        <div class="eight wide centered column">
          <h2 id="mon3" class="ui center aligned header">{{'For User' | translate}}</h2>
          <div class="ui horizontal divider"><i class="star icon"></i></div>
        </div>
      </div>


      <div class="sixteen wide column">
        <div class="ui two column center aligned stackable divided grid" >
          <div class="column column-feature">
            <div class="ui icon header">
              <img class="ui center image large" src="/assets/icons/emergency-call.png">
            </div>
            <h3 id="mon3">{{'Search for Service' | translate}}</h3>
            <p id="mon3">{{'Search the service provider for the household appliances that you want to get fixed.' | translate}}</p>
          </div>
          <div class="column column-feature">
            <div class="ui icon header">
              <img class="ui center image large" src="/assets/icons/calendar.png">
            </div>
            <h3 id="mon3">{{'Get Online Appointment' | translate}}</h3>
            <p id="mon3">{{'Book the appointment via online more conveniently by scheduling with the specific date and time for the appointment.' | translate}}</p>
          </div>
        </div>
      </div>


      <!--<div class="center aligned sixteen wide column">
            <button class="ui green right labeled icon button">Learn More<i class="angle double right icon"></i></button>
          </div>-->
    </div>
  </div>
</div>
<!-- User Information Segment -->

<!-- Service Provider Information Segment -->
<div class="ui basic segment">
  <div class="ui vertical feature segment ">
    <div class="ui centered page grid usersegment">

      <div class="row">
        <div class="eight wide centered column">
          <h2 id="mon3" class="ui center aligned header">{{'For Service Provider' | translate}}</h2>
          <div class="ui horizontal divider"><i class="star icon"></i></div>
        </div>
      </div>


      <div class="sixteen wide column">
        <div class="ui three column center aligned stackable divided grid">
          <div class="column column-feature">
            <div class="ui icon header">
              <img class="ui center image large" src="/assets/icons/accepted-notes.png">
            </div>
            <h3 id="mon3">{{'Accept Customer Requests Online' | translate}}</h3>
            <p id="mon3">{{'Service Provider receives appointment requests from customers via online more conveniently which makes service provider easier to manage the incoming requests.' | translate}} 
            </p>
          </div>
          <div class="column column-feature">
            <div class="ui icon header">
              <img class="ui center image large" src="/assets/icons/calendar3.png">
            </div>
            <h3 id="mon3">{{'Reschedule Appointments' | translate}}</h3>
            <p id="mon3">{{'Service Provider can reschedule the customer appointments if many of the appointments conflict at the specific time and date.' | translate}} </p>
          </div>
          <div class="column column-feature">
            <div class="ui icon header">
              <img class="ui center image large" src="/assets/icons/team.png">
            </div>
            <h3 id="mon3">{{'Adaptablility to the traditional work flow' | translate}}</h3>
            <p id="mon3">{{'Service Provider can easily make use of the online appointment system in their traditional work flow without completely changing to the new system.' | translate}}</p>
          </div>
        </div>
      </div>


      <!--<div class="center aligned sixteen wide column">
            <button class="ui green right labeled icon button">Learn More<i class="angle double right icon"></i></button>
          </div>-->
    </div>
  </div>
</div><br>
<!-- Service Provider Information Segment -->

<!-- Service Provider Registration -->
<div class="ui centered grid">
  <div class="sixteen wide column">
    <div class="ui basic centered registerSegment segment" id="here">

      <div class="ui centered grid">
        <div class="row">
          <div class="eight wide computer sixteen wide mobile column">
            <h3 id="mon3" class="ui center aligned block header">{{'Register As Service Provider' | translate}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="eight wide computer sixteen wide mobile column">

            <div class="ui fluid tall stacked segment">

              <div class="content">

                <form class="ui form" (ngSubmit)="onSubmit()" [formGroup]="spRegisterForm">

                  <div class="ui red message" *ngIf="name.errors && (name.touched || name.dirty)" id="mon3">
                    {{'Please enter company name' | translate}}
                  </div>

                  <div class="field">
                    <label id="mon3">{{'Company Name' | translate}}</label>
                    <div class="ui left icon input">
                      <i class="user icon"></i>
                      <input required type="text" name="name" placeholder="{{'Company Name' | translate}}" required [formControl]="name" id="mon3">
                    </div>
                  </div>

                  <div class="ui red message" *ngIf="(phone.touched || phone.dirty) && phone.hasError('validPh')" id="mon3">
                    {{'Please enter valid phone number' | translate}}
                  </div>
                  <div class="field">
                    <label id="mon3">{{'Phone Number' | translate}}</label>
                    <div class="ui left icon input">
                      <i class="call icon"></i>
                      <input required type="text" name="phone" placeholder="{{'Phone Number' | translate}}" [formControl]="phone" id="mon3">
                    </div>
                  </div>

                  <div class="ui red message" *ngIf="email.errors && (email.touched || email.dirty)" id="mon3">
                    {{'Please enter email address' | translate}}
                  </div>
                  <div class="field">
                    <label id="mon3">{{'Email' | translate}}</label>
                    <div class="ui left icon input">
                      <i class="mail icon"></i>
                      <input required type="text" name="email" placeholder="{{'Email' | translate}}" [formControl]="email" id="mon3">
                    </div>
                  </div>

                  <button class="ui fluid secondary button" type="submit" [ngClass]="{disabled : spRegisterForm.invalid}" id="mon3">{{'Register' | translate}}</button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>